import React from "react"
import { Link } from "gatsby"
import { FaArrowRight } from "react-icons/fa"

import Layout from "../components/layout.component"
import Seo from "../components/seo.component"

const SuccessPage = (props) => {
  const params = new URLSearchParams(props.location.search)
  const successType = params.get("type")

  return (
    <Layout className="success">
      <Seo title="Success" />
      <section>
        <div className="container">
          <div
            className="has-text-centered is-flex is-flex-direction-column is-justify-content-center"
            style={{ minHeight: "50vh" }}
          >
            <h1 className="title has-text-success">Success</h1>
            <p className="is-size-6">
              {successType === "contact" && (
                "Thank you for contacting me. Your message has been received and I will respond as soon as possible."
              )}
              {successType === "subscribe" && (
                "Thank you for signing up for news and content updates. I look forward to sharing some exciting new content with you."
              )}
              {successType === "unsubscribe" && (
                "We're sorry to see you go. Please allow up to 24 hours for you to be removed from the mailing list."
              )}
            </p>
            <Link className="button is-success" to="/" style={{ alignSelf: 'center', marginTop: '2rem' }}>Head Home <FaArrowRight className="ml-4" /></Link>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
